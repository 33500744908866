
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.linkWrapper {
  text-decoration: none;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  border: transparent;
  border-radius: 50px;
  background: linear-gradient(180deg, $color-primary-blue 0, $color-dark-blue 100%);
  color: $color-white;
  font-family: $font-proxima;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

  @include breakpoint(large) {
    &:hover {
      border: 2px solid $color-primary-blue;
      background: transparent;
      color: $color-primary-blue;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}

.secondary {
  border: 2px solid $color-white;
  background: transparent;
  color: white;

  @include breakpoint(large) {
    &:hover {
      border: none;
      background: linear-gradient(180deg, $color-primary-blue 0, $color-dark-blue 100%);
      color: white;
    }
  }
}

.orange {
  background: $color-orange;
  color: white;

  @include breakpoint(large) {
    &:hover {
      border: 2px solid $color-orange;
      background: transparent;
      color: $color-orange;
    }
  }
}
