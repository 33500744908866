.custom-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.custom-dot {
  width: 15px;
  height: 15px;
  background-color: #333;
  border-radius: 50%;
  cursor: pointer;
}

.slick-active {
  .custom-dot {
    background-color: #154b8e;
  }
}
