
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.paragraph {
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}

.paragraph1 {
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}

.paragraphBolder {
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.legal {
  font-family: $font-primary;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
}

.headline1 {
  font-family: $font-proxima;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
}

.headline1bolder {
  font-family: $font-proxima;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
}

.headline2 {
  font-family: $font-proxima;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
}

.headline2small {
  font-family: $font-proxima;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

.headline2smallerlighter {
  font-family: $font-proxima;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.headline2smaller {
  font-family: $font-primary;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.headline3 {
  font-family: $font-proxima;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
}

.headline3bold {
  font-family: $font-proxima;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

.headline3small {
  font-family: $font-proxima;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

.headline4 {
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.list {
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

.disclaimer {
  font-family: $font-primary;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

.disclaimerNew {
  padding: 0;
  margin: 2px 0px 0px 0px;
  color: #707376;
  font-family: $font-primary;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  @include breakpoint(medium) {
    font-size: 16px;
    line-height: 22px;
  }
}
