
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.footer {
  @media only screen and (max-width: 1023px) and (min-width: 639px) {
    max-height: 402px;
    margin: 64px 32px 32px 32px;
  }
  @include breakpoint(large) {
    max-height: 360px;
    margin: 64px 64px 32px 64px;
  }
  @include breakpoint(small) {
    max-height: 676px;
    margin: 64px 16px 32px 16px;
  }

  .logoNav {
    @media only screen and (max-width: 1023px) and (min-width: 639px) {
      display: flex;
    }
    @include breakpoint(large) {
      display: flex;
    }
    @include breakpoint(small) {
      display: block;
    }

    .upperNav {
      display: flex;
      margin: 0;
      overflow: hidden;
      @media only screen and (max-width: 1023px) and (min-width: 639px) {
        margin: 0;
      }
      @include breakpoint(large) {
        margin: 0;
      }
      @include breakpoint(small) {
        margin: 16px 0 32px 0;
      }

      a {
        color: #535353;
        font-size: 14px;
        @media only screen and (max-width: 1023px) and (min-width: 639px) {
          margin-left: 48px;
        }

        @include breakpoint(small) {
          margin: 0 40px 0 0;
        }
        @include breakpoint(large) {
          margin-left: 48px;
          line-height: 1.2;
        }
      }
    }
  }

  .nav {
    margin-top: 20px;
    columns: 2;

    @include breakpoint(medium) {
      margin-top: 0;
      margin-left: 50px;
    }
  }

  .lowerNav {
    margin: 32px 0 0 0;
    padding: 0;
    @include breakpoint(medium) {
      display: flex;
      align-items: baseline;
    }

    li {
      margin-right: 16px;
    }
  }

  .navItem {
    list-style: none;

    @include breakpoint(large) {
      margin-right: 22px;
    }
    @include breakpoint(small) {
      margin-right: 0;
    }

    a {
      color: $color-dark-gray;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 300;
      line-height: 1px;

      &:hover {
        color: #0078a0;
      }
    }

    span {
      color: $color-dark-gray;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 300;
      line-height: 1px;
      text-decoration: underline;
      &:hover {
        color: #0078a0;
      }
    }
  }

  .legalAnno {
    margin-top: 38px;
    color: blue($color: #000);
    font-weight: 400;
    @include breakpoint(small) {
      margin-top: 32px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      color: #535353;
      font-weight: 400;

      @include breakpoint(medium) {
        margin-bottom: 5px;
      }
    }
  }

  .legal {
    margin-top: 21px;

    p {
      margin-top: 0;
      margin-bottom: 0;
      color: #535353;
      font-weight: 400;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }
}
