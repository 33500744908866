
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.listItem {
  padding: 15px;
  list-style-type: none;
  color: $color-dark-blue;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    color: $color-primary-blue;
  }
}

.listItemWrap {
  border-top: 1px solid rgba(34, 36, 38, 0.15);

  &:first-child {
    border: none;
  }

  &.active {
    background-color: #0000000f;
  }
}

.listContent {
  display: flex;
  margin: 0;

  &:hover {
    background-color: #0000000f;
    cursor: pointer;
  }
}
